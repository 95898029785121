<template>
	<div>
		<h4 class="h4_title">意见反馈</h4>
		
		<van-form @submit="formSubmit" ref="formItem" :show-error-message="false">

			<van-field
				clearable
				type="text"
				v-model="formItem.name"
				name="name"
				label="标题"
				placeholder="请输入反馈标题"
				:rules="[{ required: true, trigger: 'onSubmit', message: '请输入反馈标题' }]"
			/>

			<van-field
				clearable
				type="textarea"
				v-model="formItem.description"
				name="description"
				label="详细描述"
				autosize
				rows="6"
				placeholder="请输入详细描述"
				:rules="[{ required: true, trigger: 'onSubmit', message: '请输入详细描述' }]"
			/>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	export default {
		name: 'feedback_index',
		data() {
			return {				
				formItem: {
					token: "",
					name: "",
					description: "",
				},
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
			}
		},
		methods: {
			formSubmit() {
								
				this.$toast.loading({
					message: '请稍等...',
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'feedback/insert', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {

						this.formItem.name = ""
						this.formItem.description = ""
				
						this.$toast.success({
							message:'意见反馈已成功提交'
						})
						
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
		},
	}
</script>
